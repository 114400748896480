$(document).ready(function() {
  publicMenu();
  setHeight();

  $(window).resize(function() {
    setHeight();
  });

  $("#mobile-side-open").on("click", function() {
    $(".mobile-side-menu").addClass("open");
    $(".mobile-menu-backdrop").fadeIn(200);
    $("body").addClass("o-hidden");
  });

  $("#mobile-menu-backdrop, .mobile-side-nav>li>a").on("click", function() {
    $(".mobile-side-menu").removeClass("open");
    $(".mobile-menu-backdrop").fadeOut(200);
    $("body").removeClass("o-hidden");
  });

  $(".card_filters_open").on("click", function() {
    $(".card-filters").slideToggle(200);
  });

  customHomeProductAnimation();

  $(document).on("click", ".hero-video", function() {
    $(".hero-video").addClass("animated");
    $(".circles-video").addClass("out");
    var url = $(this).attr("href");
    var media = createIframe(url);
    var mediaDiv = document.createElement("div");

    mediaDiv.className = "media-iframe";

    $(".video-overlay")
      .find(".media")
      .html(mediaDiv)
      .append('<div class="close"><i class="mdi mdi-close"></i></div>');
    $(mediaDiv).html(media);

    setTimeout(function() {
      $(".video-overlay").fadeIn("500");
    }, 600);

    $(".video-overlay, .video-overlay .close").on("click", function() {
      $(this).fadeOut("500", function() {
        $(this)
          .find(".media")
          .empty();
        $(".circles-video").removeClass("out");
        setTimeout(function() {
          $(".hero-video").removeClass("animated");
        }, 100);
      });
    });
    return false;
  });

  // $('.dropdown-menu').on('click', function (e) {
  //     console.log('stopProp');
  //     if ($(this).hasClass('form')) {
  //         console.log('stopProp');
  //         e.stopPropagation();
  //     }
  // });

  $(document).on("click", ".replay", function() {
    clearTimeout(timeout);
    step = 0;
    $(".how-step").removeClass("active");
    // $('.how-step:first-child').addClass('active');
    laptopAnimation();

    return false;
  });
});

var step = 0;
var time;
var isVisible = false;
var timeout;

var lastScrollTop = 0;

$(window).scroll(function() {
  var scroll_top = $(window).scrollTop();

  if ($("#homeAnimation").length) {
    var animationAnchor = $("#homeAnimation").offset().top;

    if (
      scroll_top > animationAnchor - 400 &&
      scroll_top < animationAnchor + $("#homeAnimation").height() - 240
    ) {
      if (!isVisible) {
        isVisible = true;
        step = 0;
        time = 1000;
        $(".how-step").removeClass("active");
        // $('.how-step:first-child').addClass('active');
        laptopAnimation();
      }
      console.log("focus");
    } else {
      isVisible = false;
      clearTimeout(timeout);

      // console.log('not-focus');
    }
  }

  if (scroll_top > 50) {
    $(".main-wrapper").addClass("scrolled");
  } else {
    $(".main-wrapper").removeClass("scrolled");
  }

  if (scroll_top > 30) {
    scrollDirection();
  }
});

function customHomeProductAnimation() {
  var slideCount = 3;
  var activeIndex = 0;
  var outIndex;

  setInterval(function() {
    if ($(".home-product .slide.active").index() == slideCount - 1) {
      activeIndex = 0;
      outIndex = 2;
    } else {
      activeIndex++;
      outIndex = activeIndex - 1;
    }

    $(".home-product .slide.active").removeClass("active");
    $(".home-product .slide.out").removeClass("out");
    $(".home-product .slide")
      .eq(activeIndex)
      .addClass("active");
    $(".home-product .slide")
      .eq(outIndex)
      .addClass("out");
  }, 4000);
}

function scrollDirection() {
  var st = $(this).scrollTop();

  if (st > lastScrollTop) {
    // downscroll code
    $("body")
      .addClass("scrollDown")
      .removeClass("scrollUp");
  } else {
    $("body")
      .addClass("scrollUp")
      .removeClass("scrollDown");
    // upscroll code
  }
  lastScrollTop = st;
}

function parseVideo(url) {
  url.match(
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/
  );
  if (RegExp.$3.indexOf("youtu") > -1) {
    var type = "youtube";
  } else if (RegExp.$3.indexOf("vimeo") > -1) {
    var type = "vimeo";
  }

  return {
    type: type,
    id: RegExp.$6
  };
}

function createIframe(url, width, height) {
  // Returns an iframe of the video with the specified URL.
  var videoObj = parseVideo(url);
  var $iframe = $("<iframe>", {
    width: width,
    height: height
  });
  $iframe.attr({
    frameborder: "0",
    allowfullscreen: ""
  });

  if (videoObj.type == "youtube") {
    $iframe.attr("src", "https://www.youtube.com/embed/" + videoObj.id);
  } else if (videoObj.type == "vimeo") {
    $iframe.attr({
      webkitallowfullscreen: "",
      mozallowfullscreen: ""
    });
    $iframe.attr(
      "src",
      "//player.vimeo.com/video/" + videoObj.id + "?autoplay=1"
    );
  }
  console.log(videoObj.id);
  return $iframe;
}

function laptopAnimation() {
  if (step == 0) {
    time = 1000;
  } else if (step == 3) {
    time = 4400;
  } else if (step == 6) {
    time = 5600;
  } else {
    time = 3800;
  }
  timeout = setTimeout(function() {
    $(".how-step").each(function(i) {
      if (i == step) {
        var titleSlide = $(this).find(".text");
        var titleList = $(this)
          .find(".text")
          .text()
          .split("");
        $(this)
          .find(".text")
          .text("");

        $.each(titleList, function(idx, elem) {
          //create a span for the letter and set opacity to 0
          var newEL = $("<span/>")
            .text(elem)
            .css({
              opacity: 0
            });
          //append it to the welcome message
          newEL.appendTo(titleSlide);
          //set the delay on the animation for this element
          newEL.delay(idx * 50);
          //animate the opacity back to full 1
          newEL.animate(
            {
              opacity: 1
            },
            400
          );
        });
        if (i == 4) {
          $(".how-steps").addClass("show-btn");
        } else if (i == 5) {
          $(".how-steps").addClass("show-btn");
          setTimeout(function() {
            $(".how-steps").addClass("hovered");
          }, 500);
        } else {
          $(".how-steps").removeClass("show-btn hovered");
        }
        $(this).addClass("active");
      }
    });

    this.step++;

    if (this.step == 10) {
      this.step = 0;
    } else {
      laptopAnimation();
    }
  }, time);
}

function publicMenu() {
  $(document).on("click", "#open-mobile", function() {
    if ($(this).hasClass("open")) {
      $(".mobile-nav-wrapper")
        .find(".blackdrop")
        .fadeOut(350);
      $(this).removeClass("open");
      $(".mobile-nav-wrapper").removeClass("open");
      $("body").removeClass("menu-open");
    } else {
      $(this).addClass("open");
      $(".mobile-nav-wrapper")
        .find(".blackdrop")
        .fadeIn(350);
      $(".mobile-nav-wrapper").addClass("open");
      $("body").addClass("menu-open");
    }

    return false;
  });

  $(document).on(
    "click",
    ".mobile-menu .menu > li > a, .mobile-nav-wrapper .blackdrop",
    function() {
      $("body").toggleClass("menu-open");
      $("#open-mobile, .mobile-nav-wrapper").toggleClass("open");
      $(".public-content").toggleClass("push");
      $(".mobile-nav-wrapper")
        .find(".blackdrop")
        .fadeToggle(350);
    }
  );
}

function laptopHome() {
  $(".startAnimation").fadeOut(1200);
  $(".how-step").removeClass("out active");
  $(".step0").addClass("active");
  var step = 0;
  var refreshId = setInterval(function() {
    $(".step" + step)
      .addClass("out")
      .removeClass("active")
      .removeClass(function() {
        if (step == 4) {
          $(".step5 .how-step-btn").addClass("hovered");
          setTimeout(function() {
            $(".step5 .how-step-btn").addClass("clicked");
            setTimeout(function() {
              $(".step5 .how-step-btn").removeClass("clicked");
            }, 350);
          }, 2400);
        }

        $(this)
          .next()
          .addClass("active");

        if (step == 7) {
          $(".startAnimation.replay")
            .delay(500)
            .fadeIn(500);
          clearInterval(refreshId);
        }
        step++;
      });
  }, 4000);
}

function homeAnimation() {
  var counter = 0;
  $(".step" + counter)
    .addClass("out")
    .removeClass("active")
    .removeClass(function() {
      console.log(counter);
      if (counter == 3) {
        setTimeout(function() {
          $(".how-step-btn").addClass("hovered");
          // $(".text").removeClass("up");
          // $(".text.in").addClass("sn");
          setTimeout(function() {
            $(".how-step-btn").addClass("clicked");
          }, 2000);
        }, 2000);
      }

      $(this)
        .next()
        .addClass("active");
      if (counter == 6) {
        $(".startAnimation")
          .delay(500)
          .fadeIn(500);
        // clearInterval(refreshId);
      }
      counter++;
    });
}

function setHeight() {
  var winHeight = $(window).height();
  var sidebarFilters = 68;
  var sidebarChannels = 123;
  // var groupDialog = $('.group-dialog').height();

  
  $(".r-sidebar .side-scroll-filters").css({
    height: winHeight - sidebarFilters
  });

  $(".r-sidebar .side-scroll-channels").css({
    height: winHeight - sidebarChannels
  });

  // $('.group-dialog .scroll-container').css({height: groupDialog - 64});
}

// ### ADDED BY RIFNI

function isBlank(str) {
  return !!(str || "").match(/^\s*$/);
}

function copyToClipboard(element) {
  var code = $(element).val();

  var $temp = $("<input>");
  $("body").append($temp);
  $temp.val(code).select();
  document.execCommand("copy");
  $temp.remove();

  return code;
}

function initPulsesCardSlideUp() {
  $.fn.visible = function(partial) {
    var $t = $(this),
      $w = $(window),
      viewTop = $w.scrollTop(),
      viewBottom = viewTop + $w.height(),
      _top = $t.offset().top,
      _bottom = _top + $t.height(),
      compareTop = partial === true ? _bottom : _top,
      compareBottom = partial === true ? _top : _bottom;

    return compareBottom <= viewBottom && compareTop >= viewTop;
  };
}
