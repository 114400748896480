var en_GB = {

        entity: {
            name_full: "Start Pulsing Limited"
        },

        address: {
            "address_full"             : "75 Bermondsey Street, London SE1 3XF",
            "address_split_street"     : "75 Bermondsey Street",
            "address_split_country"    : "London SE1 3XF",
            "phone"                    : "+44 (0) 208834 3467",
            "email"                    : "info@onepulse.com"
        },

        navbar: {
            toggle_navigation: 'Toggle Navigation',
            dashboard: 'Dashboard',
            pulses: 'Pulses',
            communities: 'Communities',
            panels: 'Channels',
            my_account: 'My Account',
            settings: 'Settings',
            logout: 'Logout',
            register: 'Register',
            login: 'Login',
            sign_up: "Sign up"
        },
    
        toolbar: {
            created_at: "Created at",
            published_at: "Published at",
            started_at: "Started at",
            updated_at: "Updated at",
            ended_at: "Ended at",
            expired_at: "Expired at",
            title: "Title",
            descending: "Descending",
            ascending: "Ascending",
            none:"None",
            all: "All",
            read: "Read",
            unread: "Unread",
            starred: "Starred",
            starred_by_op: "Starred by OnePulse",
            date: "Date",
            status: "Status",
            type: "Type",
            order_by_tooltip: "Order by",
            order_direction_tooltip: "Order Direction",
            select_tooltip: "Select",
            filter_by_tooltip: "Filter by",
            mark_as_read_tooltip: "Mark selected as read",
            delete_selected_tooltip: "Delete Selected",
            refresh_inbox_tooltip: "Refresh Inbox",
            list_view: "List View",
            grid_view: "Grid View"  
        },
    
        account: {
            profile: {
                title: "Profile",
                subtitle: "Manage your personal user settings: emails, passwords, the lot!",
                update_logo: "Upload your profile image",
                edit_details: "Edit Details",
                administrator: "Administrator",
                cancel: "Cancel",
                update: "Save",
                change_password: "Change Password",
                name_label: 'Name',
                email_label: 'Email',
                psw_new_label: 'New Password',
                psw_confirm_label: 'Confirm New Password',
                training_toggle: 'Toggle training'
            }, 
            password: {
                title: "Password",
                subtitle: "Manage your passwords",
            },
            company: {
                title: "Current workspace",
                title_workspace_management: "Workspace management",
                subtitle_workspace_management: "View your existing workspaces, or create a shiny new one.",
                subtitle: "Manage your current workspace",
                subtitle_other_workspaces: "Other Workspaces",
                add_new_workspace: "Create new Workspace",
                update_logo: "Upload your company logo",
                edit_details: "Edit Details",
                no_company_name: "No Company Name",
                no_industry: "No Field / Industry",
                no_address: "No Address",
                no_contact_name: "No Contact Name",
                industry_label: "Field / Industry",
                address_label_1: "Address Line 1",
                address_label_2: "Address Line 2",
                postcode_label: "Postcode",
                company_label: "Company Name",
                contact_label: "Contact Name",
                workspace_name: "Workspace name",
                cancel: "Cancel",
                update: "Save",
                save_changes: "Save Changes",
                continue_workspace: "Continue in current workspace"
            },
            subscription: {
                title: "Subscription",
                subtitle: "Here is an overview of your current subscription plan. If you’d like to change or upgrade your subscription plan, you’re in the right place.",
            },
            credits: {
                title: "Credits",
                subtitle: "Here is an overview of your available Pulse response credits, including your active top up packs. If you’d like to top up your credits, you’re in the right place.",
            },
            customization: {
                title: "White Label",
                subtitle: "Customise the OnePulse platform to reflect your brand.",
                subdomain: "Apply and access the OnePulse platform through a customised subdomain."
            },
            payments: {
                title: "Payment method",
                subtitle: "Securely store your credit card for purchases within OnePulse platform.",
                card_holder: "Card Holder",
                card_expires: "Expires",
                no_card: "No credit card",
                add_card: 'Add Card',
                edit_card: 'Edit Card',
                further_info: "Don’t want to pay via credit card, or interested in a subscription? Get in touch with your account manager and they’ll help you out.",
                auto_billing: "Auto-billing",
                auto_billing_active: 'is activated',
                auto_billing_deactive: 'is de-activated',
                automatic_credit: 'Charge my card automatically for any credits used.',

            },
            purchases: {
                title: "Purchase history",
                subtitle: "This is a complete record of any purchases made via the OnePulse platform.",
                table_head_date: 'Date',
                table_head_type: 'Purchase',
                table_head_description: 'Description',
                table_head_amount: 'Amount',
                table_head_credit: 'Credit',
                table_head_payment_method: 'Method',
                table_head_actions: 'Actions',
                table_head_receipt: 'Receipt',
                no_transactions: 'No Transactions Available',
                show_more: 'Show more',
                show_less: 'Show less',
                invoice: 'Invoice',
                created: 'Created',
                spl: 'StartPulsing Limited',
                payment_method: 'Payment Method',
                item_description: 'Item description',
                item_amount: 'Amount',
                vat: 'TAX',
                total: 'Total',
                download_receipt: 'Download Receipt',
                onepulse: 'OnePulse',
                pulse: 'Pulse'
            },
            users: {
                title: "User management",
                subtitle: "The more the merrier! Manage or add teammates to work together in this workspace.",
                add_new_user: ' Add New User',
                table_head_name: 'Name',
                table_head_email: 'E-mail',
                table_head_last_login: 'Accessed',
                table_head_actions: 'Actions',
                table_head_accepted: 'Accepted',
                delete: 'Delete',
                edit: 'Edit',
            },
            reseller: {
                title: "Reseller management",
            },
            controls: {
                title: "Pulse Controls",
            },
            referrals: {
                  // title: "Referrals Management",
                title: "Referrals",
                top_title: 'Referrals',
                // top_subtitle: 'OnePulse needs more people like you. Invite your friends and earn cash rewards on anything they spend in their first month.',
                top_subtitle: 'Congratulations, you have been selected for our referral program! This allows selected power users like you, to send gift cards containing ',
                top_subtitle1: 'to anyone you think would love to use OnePulse!',
                top_subtitle2: 'A colleague in another department? A friend at another company? Gift them some free credit on us using the link below!',
                start_sharing: 'Gift responses now.',
                copy_link: 'Copy Gift Link',
                share: 'Share',
                share_via: 'or share on',
                people_referred: 'People Shared',
                total_earned: 'Total Amount Earned',
                current_balance: 'Current Balance',
                referred_clients: 'Referred Clients',
                table_head_name: 'Name',
                table_head_email: 'E-mail',
                table_head_country: 'Country',
                table_head_registration: 'Registration Date',
                table_head_last_access: 'Last Accessed Date',
                table_head_pulse_sent: 'Pulse Sent',
                table_head_reward_amount: 'Reward Amount',
            },   
            accessibility: {
                title: "Accessibility",
                subtitle: "We want to help you make the most of the OnePulse platform - that’s why we’ve included some helpful tips and tricks.",
                subtitle_2: "OnePulse pro? That's great, you can manage what team members see here.",
                subtitle_3: "Need help with something we didn't cover?  Send us an email to let us know.",
                training_screens: "Training Screens"
            } 
        },

        footer: {
            dashboard: '@:navbar.dashboard',
            pulses: '@:navbar.pulses',
            communities: '@:navbar.communities',
            panels: '@:navbar.panels',
            account: 'Account',
            settings: '@:navbar.settings',
            logout: '@:navbar.logout',
            new_pulse: 'New Pulse',
            top_up_credit: 'Purchase Credit'
        },

        public: {
            account_profile: "User Settings",
            workspace_profile: "Workspace Settings"
        },

        blog: {
            title: "The OnePulse Blog",
            description: "Finally, a research blog you look forward to reading.",
            post_title: "All posts"
        },

        pricing: {
            title: "Pricing",
            subtitle: "At OnePulse we have a range of different subscriptions to suit every business need.",
            subscriptions: {
                text_content: "Starter, Standard and Advanced subscriptions include monthly response credits. These can be used to send Pulses or to purchase Pulses in the Store.",
                text_content_1: "One credit buys one response to three questions.",
                monthly_cost: "Monthly Cost",
                monthly_credits: "Monthly Response Credits",
                additional_credit: "Cost per response credit",
                equivalent_value: "Equivalent Value",
                vat: "TAX",
                tab_subscriptions: "Subscriptions",
                tab_monthly_cost: "Monthly Cost",
                tab_monthly_credits: "Monthly Response Credits",
                tab_additional_credits: "Cost per response credit",
                tab_equivalent_value: "Equivalent Value",
                features: "Features",
                free: "No monthly subscription",
                pricing: "Contact us for pricing",
                talk: "Let's talk",
                contact_us: "Contact us",
                buy_responses_payg: "Buy response credits as and when you need them",
                buy_responses_generic: "Discounted bulk credit packs available",
                buy_responses_enterprise: "Enterprise bulk credit packs designed around your needs",
                recommended: "Recommended",
                your_subscription: "Your Subscription",
                unlimited: "Unlimited",
                upgrade: "Upgrade",
                sign_up: "Sign Up"
            }
                
        },

        landing: {
            pricing_title: 'Find the products that suits your needs.',
            subscriptions_tab: 'SUBSCRIPTIONS',
            pay_as_you_go_tab: 'PAY AS YOU GO',
            standard_price_name: 'standard',
            professional_price_name: 'professional',
            enterprise_price_name: 'enterprise',
            monthly_cost: 'Monthly Cost',
            monthly_cost_amount: '£0.40 / response',
            standard_cost_1: '£20.',
            standard_cost_2: '00',
            professional_cost_1: '£30.',
            professional_cost_2: '00',
            enterprise_cost1: 'pricing tailored',
            enterprise_cost2: 'to your needs',
            current_plan: 'YOUR CURRENT PLAN',
            choose_plan_button: 'SIGN UP',
            upgrade_now_button: "UPGRADE NOW",
            downgrade_button: "DOWNGRADE",
            tailored_1: "Pricing tailored",
            tailored_2: "to your needs",
            contact_us_button: "Contact us",
            title: "Question everything.",
            text_1: "OnePulse gets you honest answers from people in minutes,<br />so you can make decisions with confidence.",
            text_2: "What do you need to know?",
            ask_now: "Ask now",
            organizations_title: "Organisations getting answers on OnePulse",
            testimonials_title: "We don’t like to brag but we don’t mind",
            testimonials_title_2: "letting other people do it for us",
            pricing_table_head_payg: "Cost",
            pricing_table_head_unlimited: "Unlimited",
            pricing_table_head_features: "Features",
            pricing_table_per_response: "per response",
            pricing_table_free_responses: "FREE responses",

            subscriptions_items: {
                price_per_response: 'Price / response £0.40',
                incentive: 'incentive - deal',
                basic_demographics: 'Basic demographics',
                unlimited_responses: 'Unlimited responses',
                advanced_demographics: 'Advanced demographics',
                dashboards: 'Dashboards',
                extracts: 'Extracts',
                history: 'History',
                re_pulsing: 'Re-pulsing',
                timed_pulsing: 'Timed Pulsing',
                user_management: 'User Management',
                phone_support: 'Phone Support',
                insights: 'Insights',
                private_panels: 'Private Channels'
            },

            pay_as_you_go_items: {
                starter: 'starter',
                basic_demographics: '@:landing.subscriptions_items.basic_demographics'
            }
        },

        support: {
            title: "Don’t worry, we’ve got your back.",
            text: "<a href='mailto:info@onepulse.com'>Get in touch</a> explaining how we can help and we’ll get back to you within 24 hours."
        },

        contact: {
            title: "Contact us",
            press_contact: "For any press related queries, please contact our PR consultant Lisa at +44 (0)7525 204402"
        },

        panels: {
            panel_manager: 'Channel Manager',
            panel_title: 'Channel Title',
            close_panel: 'Deactivate',
            members: 'Members',
            confirmed_members: 'Confirmed Members',
            possible_members: 'Possible Members',
            active_pulses: 'Active Pulses',
            total_pulses: 'Total Pulses',
            participation: 'Participation',
            public_panels_header: 'Public',
            private_panels_header: 'Private',
            demographic_filters_header: 'Demographic filters',
            apply_filter_button: 'APPLY',
            group_filter_label: 'Channel',
            no_filter: 'No Filter',
            all_options: 'All',
            panel_name_label: 'Channel name',
            panel_name_placeholder: 'Add a Channel name',
            create_panel: 'Create Channel',
            saving: 'Saving',
            delete: 'Delete',
            show_pulses: 'Show all Pulses',
            duplicate: 'Duplicate',
            deactivate: 'Deactivate',
            create_pulse: 'Create Pulse',
            ask_question: "Ask this channel a question now",
            add_image: "Add an image to this channel",
            no_demographics: "No filters have been selected",
            save_filter: "Save filter",
            add_filter: "Add filter",
            close_editor: "Close",
            remove_demographic: "Remove demographic",
            add_filter_title: "You can optionally create a set of demographic rules to filter your chosen channel",

            
            

            code_required_toggle: "Require Code",
            code_required_desc: "Require Invite code to join.",
            visibility_toggle: "Visibility",
            visibility_desc: "Visible to users in Mobile app.",
            category: "Channel Category",
            category_desc: "Select a suitable Category for this channel.",
            
            tooltip_choose_color:"Choose a color for this channel",
            tooltip_add_image: "Add an Image to this channel",
            tooltip_share_panel: "Share",

            table_title: 'Title',
            table_description: 'Description',
            table_code: 'Code',
            table_privacy: 'Privacy',
            table_users: 'Users',
            table_status: 'Status',
            table_actions: 'Actions',
            table_status_active: 'Active',
            table_status_inactive: 'Inactive',

            hint_intro_panels: {
                intro_title: "Channels",
                intro_text: "Create a channel by filtering users in a community by specific demographics. Create a channel once and access it as many times as you need. OnePulse will keep the users in the channel up to date so you dont have to.",
                intro_setup: "Setup a channel now!",
                intro_setup_action:"Setup channel"
            },

            hint_panels: {
                filtered_panels_header: "My Filtered channels",
                filtered_panels_header_2: "What's a channel?",
                filtered_panels_1: "Modify existing channel to suit your research needs by using our demographic filters.",
                filtered_panels_2: "Not finding the right channel? Create an entirely new one by selecting your own mix of demographic filters or by sending out an invitation code.",
                filtered_panels_3: "Oh and don’t worry, they’ll all be saved here until you no longer want them.",
                filtered_panels_4: "A channel is a group of people with a shared interest who you can ask questions to whenever you want."
            },
            hint_public: {
                public_panels_header: "Public Channels",
                public_panels_1: "Each public channel is created based on our community’s personal interests, attitudes, and behaviours.",
                public_panels_2: "Use channels alongside the demographic filters to speak to relevant groups of consumers.",
                public_panels_3: "Want to create a channel that’s all yours? Head to the Private Channels tab."
            },
            hint_private: {
                private_panels_header: "Private Channels",
                private_panels_1: "If you've created your own channel, you'll find it here.",
                private_panels_2: "Build a private channel by recruiting people from the OnePulse community, or source people yourselves with a unique invite code.",
                private_panels_3: "Private channels can only be seen or accessed via your account."
            },
            no_panels_yet: {
                title: "You don't have any private channel yet!",
                new_panel: "New Panel",
                private_panels_2: "To create a private channel of your own customers, or a bespoke channel of OnePulse respondents, get in touch about our subscription plans."
            }

        },

        communities: {
            community_manager: 'Community Manager',
            close_community: 'Close',
            members: 'Members',
            potential_members: "Potential Members",
            active_pulses: 'Active Pulses',
            total_pulses: 'Total Pulses',
            participation: 'Participation',
            my_communities_header: 'My Communities',
            global_communities_header: 'Global Communities',
            demographic_filters_header: 'Demographic filters',
            apply_filter_button: 'APPLY',
            group_filter_label: 'Base Channel',
            invitation_code_label: 'Invitation Code',
            create_pulse: 'Ask this channel a question now',
            create_community: 'Create Filtered Channel',
            copy_code_button: 'Copy Code',
            duplicate: 'Duplicate',
            delete: 'Delete',
            table_title: 'Title',
            table_panel: 'Channel',
            table_filters: 'Filters',
            table_users: 'Users',
            table_possible: 'Possible',
            table_actions: 'Actions',
        },

        dashboard: {
            dashboard: "Dashboard",
            question_label: "Your question",

            pulses_sent_1: "Pulses sent",
            responses_received_1: "Responses received",
            responses_credit_30: "Response credits used in last 30 days",
            responses_credit_1: "Response credits available",

            create_pulse_title:"Get the answers you need... right now.",
            create_pulse: "Create Pulse",


            pulse_value: '£{{value}}',
            insights_header: 'Insights',
            answered_label: 'answered',

            insight_title: "Today's top insights",
            most_recent: "Most recent Pulses"
        },
    
         inbox: {
            inbox: "Inbox",
            no_messages: "You have no messages",
            search: "Search",
            search_message_tooltip: "Search message title and body text",
            important_by_op_tooltip: "This message has been marked as important by OnePulse",
        },

        login: {
            title: "Log in to OnePulse",
            login_button: "Log In",
            back:"Back",
            forgot_psw: "Forgot password?",
            signup_text: "New to OnePulse?",
            signup: "Sign up",
            paceholder_email: "Email",
            paceholder_psw: "Password",
            remember_me: "Remember me",
            app_text_1: "Want to answer questions on OnePulse?",
            app_text_2: "Login in app:",

            alert: {
                browser_outdate_title: "Hold up!",
                browser_outdate_text_1: "You are viewing this site with an unsupported browser. Features may be not work as expected.",
                browser_outdate_text_2: "Supported browsers are Chrome, Firefox, Safari and Internet Explorer (ver. 9+)",
                browser_outdate_confirm: "OK"
            }

        },

        register: {
            title: "Sign up to start Pulsing now!",
            subtitle: "We just need a few details to get you up and running",
            title_2: "Nearly there!",
            subtitle_2: "Tell us about your company.",
            top_label:"Personal details",
            top_label_2: "Company details",
            user_placeholder: "What should we call you?",
            email_placeholder: "Your email",
            password_placeholder: "Choose password",
            confirm_password_placeholder: "Confirm password",
            company_placeholder: "Company name",
            industry_placeholder: "Field / Industry",
            region_placeholder: "Default region",
            cancel: "Cancel",
            continue: "Continue",
            register_button: "Create account",
            footer_text: "Rather have the guided tour first?",
            book_demo: "Book a demo",
            footer_text_2: "Want to join the OnePulse Community and share your opinion? Download our app",

            complete_registration: "Complete registration",
            terms_conditions1:"I have read and understood the",
            terms_conditions2: "Terms & Conditions",
            already_account: "Already have an account?",
            login: "Log in"
        },

        forgot: {
            title: "Reset Password",
            subtitle: "Enter the email address that you used to register.",
            subtitle_2: "We'll send you a link to reset your password.",
            email_placeholder: "Email",
            send_button: "Send",
            footer_text: "Still need help?",
            footer_link: "Contact OnePulse Support",
            footer_text_2: "Are you a OnePulse app user?",
            footer_text_2_2: "Are you a OnePulse Client User?",
            footer_link_2: "Reset Password",
            back:"Back",
        },
    
        plans: {
            credit_balance: 'Credit Balance',
            credits_available: 'Response credits available',
            top_up_button: 'Top up',
            purchase_responses: 'Purchase Credit',
            request_responses: 'Request Credit',
            auto_billing_button: 'Auto Billing',
            change_plan_button: 'Change Plan',
            per_response: 'per response',
            monthly_credits: 'Monthly Credits',
            monthly_cost: 'Monthly Cost',
            bespoke_pricing: 'Bespoke Pricing',
            reponses: 'Responses',
            credits: "Credits",
            plan: 'Plan',
            status: 'Status',
            card_number: 'CARD NUMBER',
            none: 'None selected',
            auto_billing: 'Auto-billing',
            automatic_credit: 'Charge my card automatically for any credits used.',
            add_payment_button: 'Add Card',
            manage_payment_button: 'Edit Card',
            date_head_table:'Date',
            type_head_table:'Purchase',
            description_head_table:'Description',
            amount_head_table:'Amount',
            payment_method_head_table:'Method',
            receipt_head_table:'Receipt',
            show_more_button: 'Show more',
            show_less_button: 'Show less',
            invoice: 'Invoice',
            created: 'Created',
            spl: 'StartPulsing Limited',
            payment_method:'Payment Method',
            item_description: 'Item description',
            item_amount: 'Amount',
            vat: 'TAX',
            total: 'Total',
            further_info: "Don’t want to pay via credit card, or interested in a subscription? Get in touch with your account manager and they’ll help you out.",
            cancellation: "* You can cancel your subscription at any time, subject to a 3 month notice period. Please get in touch with your account manager for more information."
        },
    
        pulses: {
            pulses: "Pulses",
            search_placeholder: 'Search',
            search_all: "All",
            search_title: "Title",
            search_tags: "Tags",
            all_tab: 'All',
            draft_tab: 'Draft',
            scheduled_tab: 'Scheduled',
            active_tab: 'Active',
            closed_tab: 'Closed',
            archived_tab: "Archived",
            purchased_tab: "Purchased",
            case_sensitive_search_tooltip: "The title search context is case sensitive",

            delete_pulse: 'Delete',
            duplicate_pulse: 'Duplicate',
            preview_pulse: 'Preview',
            repulse_pulse: 'Target people who answered this Pulse',
            create_pulse: 'Create new Pulse',
            no_pulses:'No Pulses found!',
            table_title: 'Title',
            table_rating: 'Rating',
            table_users: 'Users',
            table_actions: 'Actions',
            download_csv: "Download CSV",
            download_xls: "Download XLS",
            
            hint_pulses: {
                pulses_header_1: 'Pulse Manager',
                pulses_header_2: 'What’s a Pulse?',
                pulses_1: 'Here, you’ll find all of your Pulses in one place so that you can easily access and manage them.',
                pulses_2: 'In just a few clicks you can create a new Pulse, save drafts, see which ones are scheduled to go out, monitor live Pulses and revisit closed ones.',
                pulses_3: 'A Pulse is a micro-survey of up to 3 questions.'
            },

            hint_title_card: {
                title_card_header: 'Pulse title',
                title_card_1:'Choose a title for your Pulse. This will be what people see in their feed before they open the Pulse.'

            },

            hint_intro_card: {
                intro_card_header: 'Introduction',
                intro_card_1:'You can add an introductory message if you want to give people more content for your questions.',
                intro_card_2: 'You can also include images and links to videos or third party sites.'

            },

            hint_icons: {
                icon_link_1: 'Whenever you see this icon, you can add a link to any third party site you want. No matter which site you include, users will always stay in the app.',
                icon_link_2: 'If you want to link to a video you can do that too. We support 2 types of videos:',
                icon_link_3: 'Public videos - these are videos that anyone can watch.',
                icon_link_4: 'Private videos - these are videos that are only available to people who have your encrypted URL.',
                icon_camera_1: 'By clicking on this icon you can add a picture to your introduction. This helps get people’s attention, and including one often leads to quicker responses.',
                icon_camera_2: 'Don’t forget that each picture must be at least 560x240px in size or bigger.'
            },

            hint_ask_another_question: {
                another_question_header: 'Ask another question',
                another_question_1: 'Start typing here to add another question to your Pulse.'
            },

            hint_question_card: {
                question_header: 'Ask a question',
                question_card_1:'Ask up to 3 questions per Pulse. Each question can include a link, image, and comments. You also have the choice between 7 answer types.',
                question_card_2: 'When writing a question, always have your audience in mind and make sure it’s as clear and concise as possible.',
                question_yes_no_header: 'Answer format: Yes/No answer',
                question_yes_no: 'This provides Pulsers with a simple “Yes” or “No” option.',
                question_exclusive_answer_header: 'Answer format: Exclusive answer',
                question_exclusive_answer: 'Use this when you want people to choose only one answer.',
                question_multiple_answer_header: 'Answer format: Multiple answer',
                question_multiple_answer: 'This allows respondents to give more than one answer.',
                question_slider_answer_header: 'Answer format: Slider answer',
                question_slider_answer: 'This gives people the opportunity to select a value within a set range. It’s particularly useful for price point testing.',
                question_drag_drop_header: 'Answer format: Drag & drop ranking',
                question_drag_drop: 'Choose this format so that people can rank options based on their preferences.',
                question_branching_header: 'Branching',
                question_branching: 'This feature allows you to take people down 1 of 3 branches based on the answer they gave for the first question.',
                question_image_answer_header: 'Answer format: Image answer',
                question_image_answer: 'Add and label up to 6 images as answer options, and keep in mind that people will only select 1 option.',
                question_shuffle_header: 'Shuffle Answers option',
                question_shuffle: 'Randomise answer options to eliminate any bias.',
                question_comment_header: 'Comment option',
                question_comment: 'Select this if you want to allow people to leave comments.'

            },

            hint_thank_you_card: {
                thanks_card_header: 'Thank You message',
                thanks_card_1:'This is your chance to thank the lovely people who took the time to answer your Pulse. You can include links to relevant campaigns,  encourage them to sign up to the service you’re researching, or get them to check out your website.'

            },

            hint_panel_select: {
                panel_select_header: 'Channel selection',
                panel_selection_1: 'A channel is a group of people who are part of the same demographic or who have a shared interest.',
                panel_selection_2: 'When you select a channel, you’re selecting the people who will be receiving your Pulse.'
            }

        },

        pulse: {
            create_pulse: 'Create Pulse',
            community: 'Community',
            title_placeholder: 'Name your Pulse',
            saving_label: 'Pulse saving...',
            saved_label: 'Pulse saved.',
            responses_singular: '{{value}} Response',
            responses_plural: '{{value}} Responses',
            finding_respondents: 'Finding suitable respondents',
            send_notifications: 'Sending push notifications',
            waiting_responses: 'Waiting for responses',
            credit: '(£{{value}}) Credit',
            send: 'Schedule Pulse',
            add_message: 'Add thank you message',
            add_intro: 'Add intro card',
            rating: 'Rating on',
            panel_selection: 'Select channel or create your own',
            share_results: 'Visible results',
            share_brand: 'Visible brand',
            share_results_tooltip: 'Allow users who answer this Pulse to see the results',
            share_brand_tooltip: ' Allow users who are answering this Pulse to see your brand name',
            help_enabled: 'Help text',
            help_enabled_tooltip: 'Enable/Disable the dynamic help in the Pulse editor' ,
            duplicate_tooltip: 'Duplicate',
            repulse_tooltip: 'RePulse',
            add_photo_tooltip: 'Add a photo',
            close_pulse_tooltip: 'Close Pulse',
            parent_pulse: "Parent Pulse",
            not_repulsed_answer: "Targeting people who answered this <span>Pulse</span>",
            repulsed_answer_1: "Targeting people who answered",
            repulsed_answer_2: "to the question",
            
            pulse_title: "Pulse",
            pulses: "Pulses",
            stop_pulse: "Stop Pulse",
            extract_data: "Extract data",
            channel: "Channel",
            tooltip_edit_channel: "Edit Channel",
            filters: "Filters",
            tooltip_edit_filters: "Edit Filters",
            add_respondents: 'Add Respondents',
            edit_date_time: 'Edit Date & Time',
            out_of: "Out of",
            duration: "Duration",
            view: "View",
            comments: "comments",
            comment: "comment",
            pulse_editor_title: "Pulse",
            question_link: "http://coolestlinkever.com",
            no_filters_selected: "No filters selected",
            pulse_controls: "Pulse controls",
            editor_controls: "Editor Controls",
            save_pulse: "Save Pulse",
            loading_filter: "Loading filter",
            chain_pulse: "Target people who gave this answer",
            users_available: "respondents online",
            users_available_warning: "With any Pulse, you should be able to reach a sample size of 40%+ of this number.",
            users_available_blurb: "We recommend a minimum of 500 responses to get a representative read across your chosen demographics and reduce margin of error.",
            filter_modal_headline: "Choose up to 6 different filters from the list below",
            filter_modal_international_headline: "Choose up to 6 different filters from our International list below",
            regions: "Country",
            op_audience_title: "OnePulse Audience",
            external_audience_title: "External Audience",
            external_audience_description: "This Pulse will only be available to people who have scanned your QR code or received your invite link.",
            external_audience_description_1: "Once your Pulse is published the invite link will appear here.",
            external_audience_description_2: "Alternatively, tap the Invite icon",
            external_audience_description_3: "at the top right corner of any active Pulse.",
            external_audience_description_4: "Invite people to this Pulse by using any of the invite links below.",
            external_audience_description_5: "Alternatively, tap the Invite icon",
            external_audience_description_6: "at the top right corner of any active Pulse.",
            external_audience_description_new: 'You QR Code and invite link will be available here after publish.',

            help_dropdown: {
                title: 'Pulse Editor Help',
                text_1: 'Need some help with your Pulse?',
                text_2: 'We’ve got you. Turn on Help text with control below. We will prompt you with help messages to get your Pulses sent.',
            },

            share_menu: {
                title: "Share Results",
                no_share_description: "Sharing link will be available once this Pulse is active.",
            },

            invite_menu: {
                title: "Invite Respondents",
                no_share_description: "Invite link will be available once this Pulse is active.",
            }
        },

        pulse_title: {
            tooltip_edit_title: 'Edit Title',
            external_info_description_1: "The Pulse is only available to users who have scanned your QR code or have received your invite link.",
            external_info_description_2: "at the top right of this page to start inviting respondents.",
            external_info_description_3: "The Pulse was only available to users who had received the unique Pulse invite link.",
        },

        question: {
            draft_label: '(Draft)',
            title_placeholder: 'Ask a question',
            another_title_placeholder: 'Add another question',
            option_placeholder: 'Option {{value}}',
            add_option: 'Add another option',
            remove_option:'Remove option',
            reorder_option:'Re-order option',
            branch: 'Branch',
            delete: 'Delete',
            edit: 'Edit',
            tooltip_edit: 'Edit Question',
            boolean_true: 'Yes',
            boolean_false: 'No',


            image_label_placeholder: 'Image label',
            add_image: 'Add new image',

            min_value: 'Minimum value',
            slider_interval: 'Slider intervals',
            max_value: 'Maximum value',

            cancel_edit: 'Cancel',
            save: 'Save',
            save_and_send_tooltip:'Save & send',
            remove: 'Remove',
            view_data:'View data',
            print_png:'Save chart',
            extract_data:'Extract raw data',
            extract_demo_summary: "Extract summary data",
            close_dialog:'Close',
        
            no_data: 'No result data yet!',

            tooltip_add_image: "Add image",
            tooltip_edit_image: "Edit image",
            tooltip_add_url: "Add a link or video",
            tooltip_remove_url: "Remove link or video",

            advanced_options_title: "Advanced options",
            enable_shuffle_answers: "Enable shuffle answers",
            disable_shuffle_answers: "Disable shuffle answers",
            anchor_answer: "Anchor answer position",
            enable_comments: "Enable comments",
            disable_comments: "Disable comments",
            min_value_multiple: "Minimum choices",
            max_value_multiple: "Maximum choices",

            link_clicked: "The link needs to be clicked before the user can respond to the pulse",
            valid_url_tooltip: "URL must begin with http:// or https://",

            free_text_placeholder: "Free text response...",
            word_cloud: "Word Cloud",
            all_responses: "All Responses",
            sentiment: "Sentiment"

        },

        intro: {
            intro_text: "Add an introduction or link here.",
            add_intro_card: "Add message",
            intro_placeholder: "Introduction text",
            intro_link: "http://coolestlinkever.com",
            tooltip_add_image: "Add image",
            tooltip_edit_image: "Edit image",
            tooltip_add_url: "Add link or video",
            tooltip_remove_url: "Remove link or video",
        },

        thank_you: {
            thank_you_text: "Add a final message or link here",
            add_thank_you: "Add message",
            thank_you_placeholder: "Thank you message",
            thank_you_link: "http://coolestlinkever.com",
            tooltip_add_url: "Add a link or video",
            tooltip_remove_url: "Remove link or video",
        },

        filter: {
            region_title: "Select Country",
            region_desc: "Selecting more than one region will limit the demographic filters to international demographics only.",
            close_editor: "Close without saving",
            clear_all: "Clear filters",
            save: "Save filters"
        },

        client_on_boarding: {
            question_label_placeholder: "What's your question?",
            answer_label_placeholder1: 'Option 1 (i.e. yes)',
            answer_label_placeholder2: 'Option 2 (i.e. no)',
            useInfo_email_label_placeholder: 'Email',
            useInfo_name_label_placeholder: 'Your name',
            publish: "Ask now",
            back:"Back",
            next: "Next",
            publish_tooltip: "Ask our users this question live and see the results come in Real-Time",
            signup_now: "Sign up",
            exit: "Exit",
            welcome1: "Congratulations,",
            welcome2: "Soon you’ll know more than you did before. To send more Pulses and discover additional features, sign up now.",
            signup_title1: "Nice to meet you, ",
            signup_title2: "Pop your details below to start asking questions.",
            signup_title3: "The signup process is almost complete, ",
            signup_title4: "pop your details below to start asking questions.",
            signup_title_account:"Persoanl details",
            signup_email_label_placeholder: "Your email",
            signup_password_label_placeholder: "Password",
            signup_repeatPassword_label_placeholder: "Please retype password",
            signup_title_company: "Company details",
            signup_company_label_placeholder: "Company name",
            signup_voucher_code_placeholder: "Got your hands on a voucher code? Enter it here! (optional)",
            signup_completed: "Sign up now",
            question_main_title: "Here’s a quick taste of OnePulse for you. ",
            question_title_1: "Ask an exclusive choice question on to our community of real people and get the answers in seconds.",
            question_title_2: "Try asking: Would you prefer…OR..…?",
            answer_title_1: "Set your answers",
            answer_title_2: "Tip: People will only be able to pick one answer",
            answer_title_3: "Tip: Sign up to access more answer formats",
            // email_title_1: "Enter your first and last name, along with your email address, to get the results straight to your inbox in minutes",
            email_title_1: "Fill in the details below so we can set up your free OnePulse account, where you’ll see the results arrive in seconds!",
            pulsing_title_1: " Take a well-deserved breather while Pulsers answer your question",
            pulsing_title_2: " It’s happening! Responses to your question will arrive in no time",
            pulsing_title_3: "Complete",

            form_error_question_title: "Please enter a question",
            form_error_answer: "Please enter an answer",
            form_error_answer_match: "You have duplicate answers",
            form_error_email: "Please enter a valid email address",
            form_error_contact_name: "Please enter a contact name",
            form_error_password: "Please enter a password",
            form_error_password_confirm: "Please confirm your password",
            form_error_password_match: "Your passwords do not match",
            form_error_brand_name: "Please enter your company name"
        },

        panel_size: {
            title: "You’re almost there. Before sending your Pulse, please choose your channel size below.",
            publish_title: "Publish",
            maximum_costs: 'Maximum Costs',
            responses: 'Responses',
            panel_size: 'Channel Size',
            description_1: "Please choose the number of responses required and confirm by clicking 'Publish'.",
            description_2: 'Please note that one credit will be deducted from your balance for each response you receive for this Pulse. But don’t worry, you can stop the Pulse at any time. For more information about this and others subscriptions options you can go into your account profile and click on ',
            description_3: 'Subscription & Credits',
            description_4: 'If you’re not ready to send it out just this second you can schedule a time that works for you.',
            back: 'Back',
            next: "Next",
            no_thanks: "No thanks",
            publish: 'Publish',
            schedule: 'Schedule Pulse',
            scheduler_title: "Schedule Pulse",
            topup: 'Top up',
            participant_insufficient_warning_1: "You have exceeded the ",
            participant_insufficient_warning_2: "credits you have available.",
            participant_insufficient_warning_3: "Click NEXT for purchase options.",
            participant_insufficient_warning_4: "Whoops! You’ve exceeded your credit.",
            participant_insufficient_warning_5: "Top up now by clicking NEXT.",
            participant_none_warning_1: "Whoops! There is an issue with your payment method or your credit balance.",
            participant_none_warning_2: "Click NEXT to sort this out.",
            participant_none_warning_3: "Next",
            participant_none_warning_4: "to sort this out.",
            participant_zero_warning_1: "Whoops! You cannot Pulse to zero participants.",
            channel_not_init_warning_1: "You cannot publish this pulse until your custom channel has completely initialised.",
            paid_pulse_tickbox: 'Sending Pulse to your own Channel is Free. Tick this box if you would like to reward your users anyway.',
            publish_time_warning_1_home_region: "Heads up! Many of our users will be asleep at the time you’ve selected to send your Pulse - please schedule for another time.",
            publish_time_warning_2_home_region: "Relevant time-zones, relative to UTC, are",
            publish_time_warning_1_other_region: "Heads up! Many users in the region you’ve selected will be asleep at the time you’ve selected to send your Pulse - please schedule for another time.",
            publish_time_warning_2_other_region: "Relevant time-zones, relative to UTC, are",
            publish_time_warning_1_multi_region: "Heads up! Many users in the ",
            publish_time_warning_2_multi_region: " region will be asleep at the time you’ve selected to send your Pulse - please schedule for another time.",
            publish_time_warning_3_multi_region: "Relevant time-zones, relative to UTC, are"
        },
    
        extend_panel_size: {
            update_title: 'Update',
            maximum_costs: 'Maximum Costs',
            responses: 'Responses',
            panel_size: 'Channel Size',
            description: 'Please note that costs will be deducted from your credit for each response you add for this Pulse.',
            back: 'Back',
            update: 'Update',
            date_and_time: 'Date and Time',
            less_participant_warning: 'You cannot have less respondents... error to be defined'
        },

        user: {
            help_panel_title_1: "My Profile",
            help_panel_text_1: "Here you can manage and update all information related to your OnePulse account, including editing emails or updating passwords.",
            help_panel_title_2: "Company Profile",
            help_panel_text_2: "If you are looking to make changes to your company profile - you’re in the right place.",
            help_panel_text_2_2: "Heads up! If you toggle on 'Visible Brand' when creating a Pulse, respondents will be able to view both your company logo and brand name.",
            help_panel_title_3: "Subscription & Credit",
            help_panel_text_3: "Here’s an overview of your current response credit, including any active subscriptions.",
            help_panel_text_3_2: "If you’d like to upgrade your subscription plan, top up Pulse credits, or change your preferred payment method, you’re in the right place.",
            help_panel_text_3_3: "If you need more information, feel free to get in touch with your account manager.",
            help_panel_title_4: "User Management",
            help_panel_text_4: "It's a team effort!",
            help_panel_text_4_2: "Create separate accounts for your colleagues to work together in real-time.",
            help_panel_title_5: "Purchase History",
            help_panel_text_5: "This is a complete record of any purchases made via the OnePulse platform.",
            help_panel_title_6: "Pulse Controls",
            help_panel_text_6: "We want to help you make the most of the OnePulse platform - that’s why we’ve included some helpful tips and tricks.",
            help_panel_text_6_2: "OnePulse pro? That's great, you can manage what team members see here.",
            help_panel_text_6_3: "Need help with something we didn't cover?  Send us an email to let us know."
        },

        preview: {
            preview_modal_button: "Preview Pulse",
            preview_modal_text: "To preview this Pulse on mobile please enter your mobile username* below.",
            preview_modal_placeholder: "Username",
            preview_modal_help: "*This is the username you create when downloading the OnePulse app for the first time. It must be unique to your web-based email.",
            preview_modal_error_username: "Unable to find this username. Please check & try again.",
            preview_modal_error_push_notificatons: "Push notifications must be enabled to preview Pulse. Please enable & try again.",
            success: {
                title: "Success",
                text: "Preview Pulse sent to Phone"
            }
        },

        top_up: {
            title: "Top up",
            card: "Card",
            card_details: "Card Details",
            selection: "Your Selection",
            address_holder: "Billing address and card holder",
            credits_pack: "Credits Pack",
            summary: "Purchase summary",
            next: "Next",
            confirm: "Confirm",
            confirm_pay: "Confirm & pay",
            save: "Save",
            cancel: "Cancel",
            delete: "Delete",
            back: "Back",
            close: "Close",
            card_holder: "Name of card holder",
            street_address: "Street address",
            address: "Address",
            city: "City",
            post_code: "Post code",
            thanks_1: "Thanks for your purchase!",
            thanks_2: "Your OnePulse credit is now",
            new_pulse: "New Pulse",
            preferred_card_add_title: "Add Preferred Card",
            add_payment_method: "Add Payment Method",
            purchase_credits: "Purchase Credits",
            edit_card_details: "Edit Card Details",
            remove_card: "Remove Card",
            change_credit_card: "Add Card",
            preferred: "Preferred",
            responses: "Responses",
            total: "Total",
            autobill_product_title: "Auto Billed",
//            autobill_summary_1: "Make Pulsing easier with Auto-Billing!",
            autobill_summary_1: "Your card details will be saved to enable auto-billing for future purchases. You can remove these card details and/or disable auto-billing at any time in your Account Profile.",
//            autobill_summary_2: "We will only charge your card when you publish a Pulse.",
            card_number: "Card number",
            expiration_date: "Expiration date",
            security_code: "Security code",
            save_as_preferred: "Save as preferred",
            no_credit_card: "Don’t want to pay via credit card? Get in touch with your account manager and they’ll help you out.",
            invoice: "Purchase on invoice.",
            invoice_2: "As the Purchase Manager for this workspace you can purchase these credits on invoice."
        },

        subscriptions: {
            title: "Find the plan that suits your needs.",
            description: "Upgrade to a monthly subscription to pay less per response credit and unlock features.",
            title_purchased: "Subscription Upgraded",
            payment_title: "Change Your Subscription",
            selection: "Your Selection",
            thanks_1: "The update is in! Credits will now be in your account and we can settle up in your next billing cycle. ",
            new_pulse: "New Pulse",
            close: "Close",
            upgrade_title: "Time to upgrade?",
            upgrade_text: "This feature isn’t available on your current plan. Click below to find the best option for you.",
            upgrade_button: "Upgrade now"
        },

        billing_options: {
            title: "Choose Billing Option",
            auto_billed_title: "AUTO BILLED",
            auto_billed_description: ""
        },

        user_management: {
            allow_1_login: "You don't have access to multiple logins with your current subscription.",
            allow_5_logins: "You can create up to 5 logins with your current subscription. Need more?",
            allow_10_logins: "You can create up to 10 logins with your current subscription.",
            view_plans_upgrade: "Click here to view plans and upgrade"
        },

        profile: {
            title: "User Settings",
            workspace_title: "Workspace Settings",
            update_logo: "Upload your profile image",
            edit_details: "Edit Details",
            edit_password: "Edit Password",
            login_details: "Login Details",
            change_password: "Change Password",
            update_button: "Save"
        },

        company: {
            update_logo: "Upload your company logo",
            edit_details: "Edit Details",
            industry_label: "Field / Industry",
            address_label_1: "Address Line 1",
            address_label_2: "Address Line 2",
            postcode_label: "Postcode",
            admin_label: "Company Name",
            contact_label: "Contact Name",
            account_payment_method: "Account Payment Method",
            update_button: "Save"

        },

        image_cropper: {
            tooltip_close: "Close Image Cropper",
            tooltip_save: "Save image",
            tooltip_delete: "Remove image",
            tooltip_open: "Browse for an image",
            max_image_size: "Max Image Size: 5Mb",
            question_image_dims: "Rec. dimensions: min. 800x600px",
            answer_image_dims: "Rec. dimensions: min. 450x450px"
        },

        generic: {
            yes: "Yes",
            no: "No",
            cancel: "Cancel",
            close: "Close",
            clear_all: "Clear All",
            confirm: "Confirm",
            save: "Save",
            submit: "Submit",
            apply: "Apply",
            back: "Back",
            email: "Email",
            email_address: "Email address",
            role: "Role",
            no_thanks: "No Thanks",
            view_plans: "View Plans",
            copy: "Copy",
            open_share_page: "Open Share Page",
            beta: "BETA",
            beta_tooltip: "Please be aware this is a beta feature",
            vat: "TAX",
            save_changes: "Save Changes",
            purchased: "Purchased",
            expires: "Expires",
            free: 'FREE',
            per_response: 'per response',
            subscription: 'Subscription',
            confirm_publish: 'Confirm & publish',
        },

        channel: {
            share_menu: {
                title: "Share Channel",
                no_share_description: "Sharing link will be available once this Channel is saved.",
            }
        },

    share: {
        pulse: {
            top_title: "You have been invited to give your opinion.",
            open_pulse: "Start Now",
            open_in_app: "Open in App",
            pulse_scheduled_title: "This Pulse will go live in",
            pulse_closed_title: "This Pulse is closed.",
            pulse_closed_text: "Check out the results below, or open the OnePulse app for more Pulses.",
            overlay_title: "To open this Pulse, you'll need the OnePulse app.",
            overlay_desktop_title: "OnePulse is not supported on this device. Please try again on a mobile or tablet device.",
            more_pulses_title: "Want to see more?",
            more_pulses_text: "Browse more Pulse below and see what people are saying on OnePulse right now!",
            help_title: "Having trouble opening in app?",
            help_text_1: "To open this pulse in app please do the following:",
            help_text_2: "Download the OnePulse mobile app, click on the top-right icon ",
            help_text_3: "and enter the Pulse ID",
            help_text_4: "in the mobile app.",
            footer_title_1: "Instant access to what people think.",
            footer_title_2: "you should get paid for yours.",
            footer_text_1: "Get answers to the questions that drive immediate results, right now.",
            footer_text_2: "Want to learn more?",
            footer_text_3: "Download the app for free.",
            user_footer_title_1: "Opinions are valuable,",
            user_footer_title_2: "you should get paid for yours.",
            user_footer_text_1: "Answer questions on the things that matter to you.",
            user_footer_text_2: "Download the app for free.",
            share_pulse: "Share this Pulse",
            paid_pulse: "Paid Pulse",
            copy_link: "Copy Link"
        },
        channel: {
            top_title: "You’re invited",
            top_text_1: "Join to this channel on OnePulse to answer questions about the things you care about.",
            join_channel: "Join Channel",
            no_app_title: "Hold up! Seems like you are not on a mobile device.",
            no_app_text_1: "To join this channel, download our mobile app for free then open  ",
            no_app_link: "this link",
            no_app_text_2: " again on mobile.",
            help_title: "Having trouble?",
            help_text_1: "Make sure you’ve downloaded the OnePulse app on your device and try again. Alternatively, you can also open the app and enter code",
            help_text_2: "in the Channels tab.",
            help_text_3: "into the third tab of the app.",
            join_others_title: "Join other channels popular on OnePulse.",
            join_others_text: "Browse through a selection of our channels",
            footer_title_1: "Opinions are valuable, ",
            footer_title_2: "you should get paid",
            footer_title_3: "for yours.",
            footer_text_1: "Download the app for free.",
            overlay_title: "Please open the OnePulse app to join this channel.",
            share_panel: "Share this channel",
        },
        share: "Share",
        get_op: "Get OnePulse",
        open_op: "Open OnePulse",
        app_store: "App Store",
        google_play: "Google Play"
    },
    
        training: {
            buttons: {
                next:"Next",
                back: "Back",
                dismiss: "Dismiss",
                done: "Done"
            },
            
            dashboard: {
                welcome_title: "Welcome",
                welcome_text: "Welcome to the opinion platform that turns market research into engaging, real-time conversation with real people.",
                
                slide2_title: "What is a Pulse?",
                slide2_text1: "Pulses are bite-sized surveys of up to 3 questions. You’ll send Pulses and they’ll be answered by people on the OnePulse mobile app.",
                slide2_text2: "Here’s how the magic happens...",

                slide3_title: "Decide on an audience",
                slide3_text1: "60+ data points are available for all Pulsing needs.  Attach up to 6 per Pulse to target people or segment your answers.",
                
                slide4_title: "Build your questions",
                slide4_text1: "Enter your questions, along with any images or media you’d like to share with your respondents.",
                slide4_text2: "You can select from 8 different answer types.",

                slide5_title: "It’s alive!",
                slide5_text1: "Once you’ve sent a Pulse, charts and word clouds will update in real time as responses roll in.",
                slide5_text2: "Need to analyse the data? Toggle the filters on the left-hand side or download the results in full.",

                slide6_title: "Ready, set, go",
                slide6_text1: "Hit the",
                slide6_text2: "CREATE PULSE",
                slide6_text3: "button to speak to people right now.",
            },
            
            panels: {
                welcome_title: "Welcome to Channels View",
                title_slide1: "What are Channels?",
                text_slide1: "Channels are groups of users. Channels you create are ONLY accesible by you.",
                title_slide2: "Private Channels",
                text_slide2: "Use Private Channels to invite your own customers or employees and Pulse them for free.",
                title_slide3: "Share your Channel's URL",
                text_slide3: "Once you have created a Channel you can 'Share' it via social media or an e-mail campaign.",
                title_slide4: "Private Channels",
                text_slide4: "Make your Channels 'Visible' to allow users 'in app' to follow you and receive content from you for FREE",
                title_slide5: "More Channels",
                text_slide5: "Public Channels are created by OnePulse and are available for all clients to Pulse to."
            }
        },
    
        store: {
            store: "Store",
            menu: {
                home:"Home",
                purchase_pulses: "Purchase Pulses",
                purchase_credits: "Purchase Credit",
                purchase_panels: "Purchase Channels"
            },
            home: {
                title_header: "The OnePulse Store",
                text_header: "Welcome to our Store! Get started browsing our Pulse database, buying credits to send or purchase Pulses, and checking out our credit packs and subscriptions.",
                title_pulses: "Pulse Database",
                text_pulses: "Research question keeping you up at night? Chances are, OnePulse has asked it.",
                text_pulses_1: "Our editorial team has gathered over 20 million responses from our mobile community. Browse the database below by keyword or topic, then preview the Pulses ahead of buying.",
                text_pulses_2: "Your current plan gives you a",
                text_pulses_3: "discount on all Pulses purchased!",
                title_credits: "Credit",
                text_credits: "Purchase credit to send your own Pulses, or purchase Pulses from the Store. Purchase a credit pack and receive an additional discount on your current response cost. ",
                text_credits_1: "Even better, if you’re on a subscription, we’ll honour your cost per additional response.",
                title_panels: "Purchase Channels",
                text_panels: "At OnePulse we pride ourselves on the diverse range of demographics that we can hone in on. Depending on your business model, we can target specific niche markets that were previously unavailable to you, with the aid of our crafted Channels.",
                find_out_more: "Find Out More",
                show_all: "Show All"
            },
            pulses: {
                title_header: "Purchase Pulses",
                text_header: "Research question keeping you up at night? Chances are, we’ve asked it.",
                text_header_1: "Our editorial team has gathered over 20 million responses from our mobile community. Browse the database below by keyword or topic, then preview the Pulses ahead of buying.",
                text_content: "Your current plan gives you a",
                text_content_1: "on all Pulses purchased!",
                discount: "discount"
            },
            credits: {
                title_header: "Purchase Credit",
                text_header: "Buy credit to send your own Pulses or to purchase them from the Store.",
                title_content: "Purchase a",
                title_content_1: "credit pack!",
                text_content: "Purchase a credit pack and receive an additional discount on your current response cost.",
                text_content_1: "You are on the",
                text_content_2: "subscription with a cost per response of",
                text_content_3: "Purchase a",
                purchase_an: "Purchase an",
                text_content_4: "credit pack for an additional discount.",
                text_content_5: "Your current cost per response is",
                one_credit: "* 1 response credit equates to a Pulse of 3 questions.",
                pricing: "** This pricing is valid for US & UK. For pricing in the Kenyan market, please contact ",
                credits:"Response credits",
                credit_bundle: "Credit Pack",
                discount: "Discount",
                cost: "Cost per credit",
                responses: "Responses",
                responses_1: "Responses *",
                total_cost: "Total cost (excl. TAX)",
                buy: "Purchase",
                buy_credits: "Purchase credits",
                save: "Save",
                vat: "TAX"
            },
            panles: {
                title_header: "Purchase Channels",
                text_header: "Coming Soon",
                text_content: "OnePulse community members join channels based on their interests, attitudes, and behaviours. Purchase channel access to speak to the most relevant consumer segments for your business."
            }
            
        },

        create_password: {
            title_1: "Be part of the team!",
            title_2: "You have been invited to join a Workspace",
            enter_username: "What should we call you?",
            enter_password: "Choose password",
            confirm_password: "Confirm password",
            back_to_onepulse: "Back to OnePulse",
            join_workspace: "Continue"
        },


        dialogs: {
            create_workspace: {
                title: "Create new Workspace",
                description: "Workspaces allow you to manage and collaborate on different projects through one login. Set up a workspace for each of your teams, projects or clients to easily manage access & costs.",
                get_in_touch: "Get in touch to learn more.",
            },
            pulse_up_sell: {
                title: "You could be saving",
                description_1: "This Pulse will cost",
                description_2: "to send on the PAYG plan, but it could cost as little as",
                description_3: "on one of our subscription.",
                description_4: "Check out our plans to see how much you could save.",
            },
            add_user: {
                title: "Add new User"
            },
            edit_user: {
                title: "Edit User",
                link_all_roles: "Click here to see all roles and their permissions.",
            },
            roles_description: {
                title: "User Roles & Permissions",
                admin: "The Administrator has unrestricted control of Pulses, user management, subscription, workspace and subscription details.",
                purchase_manager: "The Purchase Manager has unrestricted control of Pulses, can publish anonymously or from the brand, change user and have workspace permissions, change subscription details.",
                account_manager: "The Account Manager has full Pulse control and can publish anonymously or from the brand and can change user and workspace permissions.",
                brand_publisher: "The Brand Publisher has full Pulse control and can publish anonymously or from the brand.",
                publisher: "The Publisher can create, edit, and view Pulses results and publish anonymously.",
                writer: "The Writer can create, edit and view Pulse results.",
                analyst: "The Analyst can view Pulse results."
            }
        },

        error: {
            workspace_accept_title: "Workspace activation",
            workspace_accept_failed: "We have encountered a problem activating this workspace. Please contact support for assistance.",
            workspace_accept_already: "This workspace has already been activated."
        },

        alert: {
            pulse_gone_live: {
                title: "Boom! Your Pulse is now live.",
                text: 'Time to sit back, relax and watch the answers roll in. ',
                confirmButtonText: "OK"
            },

            pulse_completed: {
                title: "Pulse Completed!",
                text: 'Your Pulse has now been completed.',
                confirmButtonText: "OK"
            },

            pulse_close: {
                title: "Stop Pulse",
                text: 'Are you sure you want to stop this Pulse?',
                cancel_button: "Cancel",
                confirm_button: "Confirm"
            },

            pulse_save: {
                title: "Unsaved changes",
                text: 'Would you like to save this Pulse before exiting?',
                cancel_button: "Don’t save",
                confirm_button: "Save and exit",
                back_button: "Cancel"
            },

            user_responded: {
                title: "User has responded to your pulse!",
                confirmButtonText: "OK"
            },

            user_updated: {
                title: "User updated",
                text: "Your user details have been successfully updated"
            },

            remove_question: {
                title: "Are you sure?",
                text_empty: "If you delete this question it will be lost forever. Yup, forever, ever.",
                text_present: "{{text}} question and any unsaved data will be lost.",
                confirm_button_text: "Yes, delete it!"
            },

            remove_question_success: {
                title: "Done!",
                text: "Your question has been deleted."
            },

            login: {
                title: "Welcome to OnePulse!",
                text: "Logged in successfully."
            },

            login_wait: {
                title: "Please wait!",
                text: "Verifying your credentials with OnePulse."
            },

            register: {
                title: "Welcome to OnePulse!",
                text: "Logged in successfully."
            },

            register_wait: {
                title: "Please wait!",
                text: "Creating your account for OnePulse."
            },

            pulse_title_absent: {
                title: 'Whoops',
                text: 'You need to add a title before sending out your Pulse.'
            },

            pulse_panel_absent: {
                title: 'Whoops',
                text: 'You must select a Channel before publishing.'
            },

            question_text_absent: {
                title: "Whoops",
                text: 'You must type your question text.'
            },

            question_slider_absent: {
                title: "Whoops",
                text: 'You must set the slider min, max and step values.'
            },

            question_slider_min_error: {
                title: "Whoops",
                text: 'The slider min value must be less than the max value.'
            },

            question_slider_max_error: {
                title: "Whoops",
                text: 'The slider max value must be greater than the min value.'
            },

            question_slider_step_error: {
                title: "Whoops",
                text: 'The slider step value must be less than the max value and greater than 0.'
            },

            question_slider_modulus_error: {
                title: "Whoops",
                text: 'The slider range must be exactly divisible by the step value.'
            },

            question_slider_negative: {
                title: "Whoops",
                text: 'The slider values cannot be negative.'
            },

            question_has_answer_duplicates: {
                title: "Whoops",
                text: 'Answers should be unique.'
            },

            question_has_bad_minmax: {
                title: "Whoops",
                text: "Multiple Choice Min or Max out of bounds (1 - 8)"
            },

            question_min_answers: {
                title: "Whoops",
                text: "You  must enter a minimum of two answers"
            },

            not_agreed_tc: {
                title: "Terms and Conditions",
                text: "You must agree to our Terms and Conditions before signing up"
            },

            not_agreed_privacy: {
                title: "Privacy Policy",
                text: "You must agree to our Privacy Policy before signing up"
            },

            password_change_request_confirm: {
                title: "Password change request",
                text: "Please confirm that you wish to change your password"
            },

            password_change_request: {
                title: "Password change request",
                text: "A password request link has been sent to your registered email address"
            },

            password_change_no_password: {
                title: "Whoops",
                text: "Please enter a new password"
            },

            password_change_no_confirm_password: {
                title: "Whoops",
                text: "Please confirm your new password"
            },

            password_change: {
                title: "Password updated successfully",
                text: "Your password has been updated successfully"
            },

            password_create: {
                title: "Password created successfully",
                text: "Your password has been created successfully"
            },

            user_create: {
                title: "User created successfully",
                text: "The user has been created successfully"
            },

            card_details_error: {
                title: "Something has gone wrong",
                text: "Please check your card details and try again. If you continue to experience problems, please contact OnePulse support"
            },

            password_weak_error: {
                title: "Password error",
                text: "Your password is too weak. Please include at least one number, " +
                "one upper-case character and one other non-alphanumeric character." +
                "Your password should also be a minimum of 8 characters"
            },

            password_match_error: {
                title: "Password error",
                text: "Your passwords do not match"
            },

            missing_display_name: {
                title: "Display name missing",
                text: "Please enter a display name"
            },

            panel_already_exists: {
                title: "Channel name already exists",
                text: "A Channel by this name already exists. Please rename your Channel"
            },

            visible_panel_category: {
                title: "Select a Category",
                text: "A visible Channel requires a Category"
            },

            panel_require_title: {
                title: "Title missing",
                text: "Channel title is required"
            },

            panel_require_description: {
                title: "Description missing",
                text: "Channel description is required"
            },

            panel_require_code: {
                title: "Invite code missing",
                text: "Invite code is required"
            },

            required_field_missing: {
                title: "Required field missing",
                text: "A required field is empty. Please complete the form"
            },

            workspace_created: {
                title: "Workspace created",
                text: "Click the workspaces icon to switch to your new workspace"
            },

            delete_pulse_confirm: {

                title: "Delete Pulse",
                text: "Are you sure you want to delete this Pulse?"
            },

            delete_pulse_complete: {
                title: "Pulse Deleted",
                text: "Pulse successfully deleted"
            },

            delete_user_confirm: {

                title: "Delete User",
                text: "Are you sure you want to delete this User?"
            },

            delete_user_complete: {
                title: "User Deleted",
                text: "User successfully deleted"
            },

            delete_panel_confirm: {

                title: "Delete Channel",
                text: "Are you sure you want to delete this Channel?"
            },

            delete_panel_complete: {
                title: "Channel Deleted",
                text: "Channel successfully deleted"
            },

            remove_branch_pulse: {
                title: "Remove Branching",
                text: "If you remove branching from this pulse, only the questions on the currently active branch will be retained"
            },

            switch_workspace: {
                title: "Switch workspace",
                text: "Are you sure you want to switch to this workspace?"
            },

            cannot_branch_pulse: {
                title: "Cannot branch question",
                text: "A question requires a minimum of 2 and a maximum of 3 answers in order to be branched"
            },

            pulse_saved: {
                title: "Pulse saved",
                text: "Pulse saved successfully"
            },

            user_created: {
                title: "User created",
                text: "User created successfully"
            },

            invitation_accepted: {
                title: "Invitation accepted",
                text: "Workspace joined successfully"
            },

            user_edited: {
                title: "User edited",
                text: "User edited successfully"
            },

            enter_username: {
                title: "Email missing",
                text: "Please enter your email"
            },

            enter_password: {
                title: "Password missing",
                text: "Please enter your password"
            },

            captcha_error: {
                title: "Captcha error",
                text: "Please resolve the Captcha and re-submit"
            },

            industry_error: {
                title: "Industry missing",
                text: "Please select your industry and re-submit"
            },

            question_has_bad_link: {
                title: "Whoops!",
                text: "Please add http:// or https:// to the URL in your question"
            },

            intro_has_bad_link: {
                title: "Whoops!",
                text: "Please add http:// or https:// to the URL in your introduction"
            },

            message_has_bad_link: {
                title: "Whoops!",
                text: "Please add http:// or https:// to the URL in your thank you message"
            },

            participant_value_too_high_warning: {
                title: "Heads up...",
                text: "You have set the responses for this Pulse higher than the number of active respondents"
            },

            participant_value_too_high_confirm: {
                title: "Heads-up!",
                text1: "We have identified ",
                text2: " active respondents for this Pulse… do you want to proceed with sending to ",
                text3: " respondents?  If you want to increase the number of active respondents please try selecting fewer demographic filters.",
                button_back: "Take me back",
                button_confirm: "Proceed"
            },

            edit_pulse_confirm: {
                title: "Edit at your own risk...",
                text: "The edit function should be used for grammar or spelling issues. Editing question structure or answer options while a Pulse is live might compromise your data!",
                button_back: "Take me back",
                button_confirm: "Proceed"
            },

            invitation_code_copied: {
                title: "Invitation code",
                text: "Copied to clipboard successfully."
            },

            invitation_link_copied: {
                title: "Link copied",
                text: "Invitation link copied to clipboard"
            },

            pulse_id_copied: {
                title: "Pulse ID",
                text: "Copied to clipboard successfully."
            },

            share_link_copied: {
                title: "Link copied",
                text: "Pulse link copied to clipboard."
            },

            session_expired: {
                title: "Info",
                text: "Your current session has expired. You have been logged out."
            },

            payment_error: {
                title: "Info",
                text: "Something's not quite right... please re-enter your details (if this doesn't work, please contact your bank/card provider)"
            },

            exceeded_max_dems: {
                title: "Warning",
                text: "You cannot add more than 6 demographic filters to a single Pulse."
            },

            exceeded_max_quota: {
                title: "Warning",
                text: "Quotas cannot exceed 100%"
            },

            below_100_quota: {
                title: "Warning",
                text: "Quotas cannot be less than 100%"
            },

            general_100_quota: {
                title: "Warning",
                text: "Quotas must total 100% for the selected values of a given demographic"
            },

            quota_error: {
                title: "Warning",
                text: "There is an issue with one of the activated quotas. Please review"
            },

            generic_error: {
                title: "Error",
                text: "We cannot process your request at this time. Please contact support"
            },

            incorrect_role_permissions: {
                title: "Error",
                text: "You don't have the correct role permissions!"
            },

            private_panel_created: {
                title: "Channel created",
                text: "Private Channel created successfully"
            },

            must_select_at_least_one_region: {
                title: "Heads-up!",
                text: "You must select at least one region to publish to"
            },

            delete_payment_method: {
                title: "Delete payment method",
                text: "Are you sure you want to delete this payment method?"
            },

            request_credits: {
                title: "Request credits",
                text: "Please request credits for this workspace from your Purchase Administrator"
            },
            
            multi_login_alert: {
                title: "Session Already Active",
                text: "It looks like someone is already logged in to this account, please ",
                text1: "logout of your other session",
                text2: "or try again later.",
                text3: "Alternatively, you can upgrade your plan to add more login sessions to your account.",
                text4: "Find out more!",
                button_close: "Close",
            },
            
            maintenance: {
                title: "We'll be right back...",
                text: "OnePulse is in temporary maintenance mode while we work to improve your experience, but will be back very soon! Apologies for any inconvenience caused."
            },

            successful_topup: {
                title: "Success",
                text: "credits have been added to your account"
            },

            unread_messages: {
                title: "Unread messages",
                text: "You have unread messages in your Inbox."
            },

            regions_changed: {
                title: "Region(s) changed",
                text: "Please note that by changing region selection, any previously selected local demographics will be removed. Please check and update your demographic selections now to make sure you’re speaking to the right people."
            },

            payout_successful: {
                title: "Success",
                text: "Withdrawal successful."
            },

            referral_payout_zero: {
                title: "Payout warning",
                text: "Please enter a payout amount greater than zero."
            },

            cannot_live_edit: {
                title: "Editing unavailable",
                text: "To ensure the highest data quality, text edits to Pulses with 200+ respondents are not permitted."
            },

            workspace_exists: {
                title: "Workspace exists",
                text: "A workspace by that name already exists for this profile. Please choose another name."
            },

            wl_login_pixel_size: {
                title: "Login logo image width too large",
                text: "Login logo image width may not be greater than 500px."
            },
            wl_bar_pixel_size: {
                title: "Navbar logo image width too large",
                text: "Navbar logo image width may not be greater than 200px."
            },
            wl_login_pixel_size_empty: {
                title: "Login logo image width required",
                text: "Login logo image width needs to be greater than zero."
            },
            wl_bar_pixel_size_empty: {
                title: "Navbar logo image width required",
                text: "Navbar logo image width needs to be greater than zero."
            },
            participant_count_too_high: {
                title: "Participant count exceeded"
            }
        }
    };
