var initiateBrainTree = function(BRAINTREE_AUTH, paymentFormId, errorCallback, successCallback) {

    var threeDSecure;
    var deviceData;

    braintree.client.create({
        authorization: BRAINTREE_AUTH
    }, function (err, clientInstance) {
        if (err) {
            console.error(err);
            return;
        }

        braintree.dataCollector.create({
            client: clientInstance
        }, function (err, dataCollectorInstance) {
            if (err) {
                // Handle error in creation of data collector
                return;
            }
            // At this point, you should access the dataCollectorInstance.deviceData value and provide it
            // to your server, e.g. by injecting it into your form as a hidden input.
            deviceData = dataCollectorInstance.deviceData;

            braintree.threeDSecure.create({
                version: 2, // Will use 3DS2 whenever possible
                client: clientInstance
            }, function (threeDSecureErr, threeDSecureInstance) {
                if (threeDSecureErr) {
                    // Handle error in 3D Secure component creation
                    return;
                }

                threeDSecure = threeDSecureInstance;

                braintree.hostedFields.create({
                    client: clientInstance,
                    styles: {
                        'input': {
                            'font-size': '14px',
                            'font-family': 'Open Sans, sans-serif',
                            'color': '#CFCFCF'

                        },
                        ':focus': {
                            'color': 'black'
                        },
                        '.number': {
                            'border':'1px solid'
                        }
                    },
                    fields: {
                        number: {
                            selector: '#card-number',
                            placeholder: '4111 1111 1111 1111'
                        },
                        cvv: {
                            selector: '#cvv',
                            placeholder: '123'
                        },
                        expirationMonth: {
                            selector: '#expiration-month',
                            placeholder: 'MM'
                        },
                        expirationYear: {
                            selector: '#expiration-year',
                            placeholder: 'YY'
                        },
                        postalCode: {
                            selector: '#postal-code',
                            placeholder: '90210'
                        }
                    }
                }, function (err, hostedFieldsInstance) {
                    if (err) {
                        console.error(err);
                        return;
                    }

                    //Show confirm buttons
                    $('.payment-button').css('opacity', '1');

                    hostedFieldsInstance.on('validityChange', function (event) {
                        var field = event.fields[event.emittedBy];

                        if (field.isValid) {
                            if (event.emittedBy === 'expirationMonth' || event.emittedBy === 'expirationYear') {
                                if (!event.fields.expirationMonth.isValid || !event.fields.expirationYear.isValid) {
                                    return;
                                }
                            } else if (event.emittedBy === 'number') {
                                $('#card-number').next('span').text('');
                            }

                            // Apply styling for a valid field
                            $(field.container).parents('.form-group').addClass('has-success');
                        } else if (field.isPotentiallyValid) {
                            // Remove styling  from potentially valid fields
                            $(field.container).parents('.form-group').removeClass('has-warning');
                            $(field.container).parents('.form-group').removeClass('has-success');
                            if (event.emittedBy === 'number') {
                                $('#card-number').next('span').text('');
                            }
                        } else {
                            // Add styling to invalid fields
                            $(field.container).parents('.form-group').addClass('has-warning');
                            // Add helper text for an invalid card number
                            if (event.emittedBy === 'number') {
                                $('#card-number').next('span').text('Looks like this card number has an error.');
                            }
                        }
                    });

                    hostedFieldsInstance.on('cardTypeChange', function (event) {
                        // Handle a field's change, such as a change in validity or credit card type
                        if (event.cards.length === 1) {
                            $('#card-type').text(event.cards[0].niceType);
                        } else {
                            $('#card-type').text('Card');
                        }
                    });

                    $(paymentFormId).submit(function (event) {

                        //Hide confirm button text and show loading spinner
                        $('.button-text').css('display', 'none');
                        $('.button-spinner').css('display', 'block');

                        //Disable submit button
                        $('#submit').prop("disabled",true);

                        event.preventDefault();

                        hostedFieldsInstance.tokenize(function (err, payload) {

                            if (err) {
                                console.error(err);

                                //Hide loading spinner and show confirm button text
                                $('.button-text').css('display', 'block');
                                $('.button-spinner').css('display', 'none');

                                //Enable submit button
                                $('#submit').prop("disabled",false);

                                if(typeof errorCallback != "undefined") {
                                    errorCallback();
                                }

                                return;
                            }

                            threeDSecure.verifyCard({
                                amount: $('#bt_total').text().substring(2),
                                nonce: payload.nonce,
                                bin: payload.details.bin,
                                onLookupComplete: function (data, next) {
                                    next();
                                },
                            }).then(function (response) {
                                if (!response.liabilityShifted) {
                                    console.log('Liability did not shift', response);
                                    return;
                                }

                                console.log('verification success:', response);
                                if(typeof successCallback != "undefined") {
                                    successCallback(response, deviceData);
                                }
                            }).catch(function (err) {
                                console.error(err);

                                //Hide loading spinner and show confirm button text
                                $('.button-text').css('display', 'block');
                                $('.button-spinner').css('display', 'none');

                                //Enable submit button
                                $('#submit').prop("disabled",false);

                                if(typeof errorCallback != "undefined") {
                                    errorCallback();
                                }
                            });
                        });
                    });
                });
            });
        });
    });
};
