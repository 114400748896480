var primary = ".toolbar .t-menu>li.active, .toolbar .t-menu>li:focus, .toolbar .t-menu>li:hover{color:$primary!important}" +
    ".question-type .type-list md-radio-button.md-checked .md-label, .question-type .type-select md-radio-button.md-checked .md-label{border-bottom:2px solid $primary!important;color:$primary!important}" +
    ".banner-subscription{background:$primary!important}" +
    ".choose-responses md-input-container .md-input{color:$primary!important}" +
    ".choose-responses .arrows{color:$primary!important}" +
    ".schedule-pulse .info-data:not(.disabled):hover div{color:$primary!important}" +
    ".data-summary a:hover{color:$primary!important}" +
    ".subs-dialog .subs-content .highlight-desc .highlight-credits{color:$primary!important}" +
    // ".nav-bar{border-bottom:1px solid $primary!important}" +
    ".dropdown-menu ul>li>a.active .checked i{color:$primary!important}" +
    ".mobile-side-menu .mobile-side-header{background-color:$primary!important}" +
    ".pulse-card .card-field .right-menu .md-button.active{color:$primary!important}" +
    ".pulse-card .card-live-responses .live-responses{color:$primary!important}" +
    ".pulse-card .card-info .info-data:hover span{color:$primary!important}" +
    ".ils-responses .ils-responses-circle{background:rgba($primary,.06)!important}" +
    ".ils-responses .ils-responses-circle:before{background-color:$primary!important}" +
    ".ils-responses .ils-progress span{background-color:$primary!important}" +
    ".dashboard-create-pulse .dashboard-pulsing-circle:before{background-color:$primary!important}" +
    ".ils-responses .ils-responses-circle:before, .tr-image .ils-circle:before{background-color:$primary!important}" +
    ".question-type .type-select,.type-list md-radio-button.md-checked .md-label{border-bottom:2px solid $primary!important;color:$primary!important}" +
    ".question-type .type-select .md-select-value{color:$primary!important}" +
    ".question-type .options .pulse-settings li.active{border-bottom:2px solid $primary!important}" +
    ".tr-image .ils-filter i{color:$primary!important}" +
    ".tr-image .ils-filter .ils-contr span{background:$primary!important}" +
    ".tr-image .ils-60{color:$primary!important}" +
    ".tr-image .ils-qst .qst-type{color:$primary!important}" +
    ".tr-image .ils-check{color:$primary!important}" +
    ".share-pulse-card .overlay .image{background-color:$primary!important}" +
    ".share-pulse-card .overlay .color{background:$primary!important}" +
    ".pulse-controls .control-section .control-selection .target span span{color:$primary!important}" +
    ".toolbar .t-menu>li>label.active{color:$primary!important}" +
    ".toolbar .filters>a.selected{color:$primary!important}" +
    ".toolbar .pulse-status{color:$primary!important}" +
    "md-sidenav.right-sidebar .r-sidebar .search-filters .filter{background:$primary!important}" +
    ".header-share-pulses{background-color:$primary!important}" +
    ".share-row .share-header{background:$primary!important}" +
    ".share-row .share-header .shapes:before,shapes:after{background:$primary!important}" +
    ".share-row .section.share-section .share-info .highlight{color:$primary!important}" +
    // ".page-header{background:$primary!important}" +
    ".referrals-header{background:$primary!important}" +
    ".free-invite-responses .card-credit{background:$primary!important}" +
    ".settings-sidebar .menu>li>a.active{color:$primary!important}" +
    ".tag-item,.demographic-option-container{background-color:$primary!important}" +
    ".dialog-content .highlight{color:$primary!important}" +
    ".dialog-content .highlight{color:$primary!important}" +
    ".btn-primary{background-color:$primary!important;border-color:$primary!important}" +
    ".store-header .store-nav .store-menu li a.active{color:$primary!important}" +
    ".store-header .store-nav .store-menu li a:focus{color:$primary!important}" +
    ".store-header .store-nav .store-menu li a.hover{color:$primary!important}" +
    ".toolbar-search .filter-search{background-color:$primary!important}";