var secondary = ".banner-credits{background:$secondary!important}" +
    ".subs-dialog .card-credit-upsell.subscription{background-color:$secondary!important}" +
    ".subs-dialog .card-credit-upsell .discount{background:$secondary!important}" +
    ".nav-bar .inner .right-nav>li.upgrade a{color:$secondary!important}" +
    ".nav-bar .inner .right-nav>li.upgrade a:focus{color:$secondary!important}" +
    ".pulse-card .card-results .free-text-switch li.active .title{color:$secondary!important}" +
    ".pulse-card .card-results .free-text-switch li.active .icon svg{fill:$secondary!important}" +
    ".insight-card{background:$secondary!important}" +
    ".question-type .type-select,.type-list md-radio-button .type_tips{color:$secondary!important}" +
    ".tr-image .ils-switch:before{background-color:$secondary!important}" +
    ".card-credit{background-color:rgba($secondary,.1)!important;color:$secondary!important}" +
    "md-sidenav.right-sidebar .r-sidebar .side-content .filter-entry.has_value>.filter-item:before{background:$secondary!important}" +
    "md-sidenav.right-sidebar .r-sidebar .side-content .filter-entry .quotas span{color:$secondary!important}" +
    ".auth-section .auth-form .auth-steps-dots>li.active{background:$secondary!important}" +
    ".auth-section .auth-form .input-guide.strong{color:$secondary!important}" +
    ".client-on-boarding .on-board-card .input-guide.strong{color:$secondary!important}" +
    "%pulsing-circle{background:rgba($secondary,.06)!important}" +
    "%pulsing-circle:before{background-color:$secondary!important}" +
    "%gcard{background:$secondary!important}.teal{color:$secondary!important}" +
    ".card_editor_question .slider-settings .slider-counter.selected{color:$secondary!important}" +
    ".card_editor_question .start-end .start-point.selected,.card_editor_question .start-end .end-point.selected{background:$secondary!important}" +
    ".pulse-controls .control-section .control-selection .demographic .demographic-options .option{background-color:$secondary!important}" +
    "md-input-container:not(.md-input-invalid).md-input-focused .md-input{border-color:$secondary!important}" +
    ".settings-sidebar .menu>li>a.active{color:$secondary!important}" +
    ".md-button.md-default-theme.md-accent.md-raised, .md-button.md-accent.md-raised, .md-button.md-default-theme.md-accent.md-fab, .md-button.md-accent.md-fab{background-color:$secondary!important}" +
    // ".md-button.md-default-theme.md-accent, .md-button.md-accent{color:$secondary!important}" +
    "md-checkbox.md-default-theme.md-checked .md-ink-ripple, md-checkbox.md-checked .md-ink-ripple{background-color:$secondary!important}" +
    ".inbox-inner .chats .chat.user .msg{background:$secondary!important}.account-list.workspace>li .switch{color:$secondary!important}";